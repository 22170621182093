import React, { ReactNode } from "react"
import {
  MainWrapper,
  Wrapper,
} from "./Layout.style"
import { Header } from "./../Header"
import { Footer } from "./../Footer"
import { ErrorBoundary } from "../ErrorBoundary"
import { useDetectDevice } from "../../../lib/useDetectDevice"

interface Props {
  className?: string
  children: ReactNode
}

if (typeof window !== "undefined") {
  var body = document.querySelector("body")
  if (body) {
    body.style.margin = "0"
    body.style.overflowX = "hidden"
    body.style.overflowY = "auto"
  }
}

export function LayoutView({ className, children }: Props) {
  const isMobile = useDetectDevice()
  const classNames = isMobile ? "mobile" : ""
  return (
    <ErrorBoundary>
      <MainWrapper>
        <Header />
        <Wrapper className={classNames}>{children}</Wrapper>
        <Footer />
      </MainWrapper>
    </ErrorBoundary>
  )
}
